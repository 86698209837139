<template>
  <div class = "info-view">
    <div class = 'info-wrap' v-if='Object.keys(this.data).length > 0'>
      <div class = 'info-wrap_left'>
        <div class = 'info-desc' v-html='this.data.text'></div>
        <div v-if='this.mobile && this.infoData.mobile_image' class = 'info-image'>
          <img :src='this.mobileImage' />
        </div>
        <ul v-if='!this.mobile' class = 'info-links'>
          <li><a :href="'mailto:' + this.data.email" target="_blank">{{ this.data.email }}</a></li>
          <li><a :href="this.data.instagram" target="_blank">Instagram</a></li>
        </ul>
      </div>
      <div v-if='!this.mobile' class = 'info-wrap_right' :style="{ backgroundImage: this.backgroundImage }"></div>
      <div class = 'info-footer' v-else>
        <ul class = 'info-footer_links'>
          <li><a :href="'mailto:' + this.data.email" target="_blank">{{ this.data.email }}</a></li>
          <li><a :href="this.data.instagram" target="_blank">Instagram</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import api from '../utils/api'
import { returnSrc } from '../utils/src'

export default {

  name: 'Info',
  props: {
    windowHeight: Number,
    infoData: Object,
    mobile: Boolean 
  },
  // data() {
  //   return {
  //     data: {}
  //   }
  // },
  mounted() {
    // on mount, populate state with data 
    // this.getData()

  },
  computed: {
    data: function() {
      return this.infoData
    },
    mobileImage: function() {
      return this.returnImgSrc(this.infoData.mobile_image)
    },
    backgroundImage: function() {
      return 'url(' + this.returnImgSrc(this.infoData.desktop_image) + ')'
    },
  },
  methods: {
    returnImgSrc: function(f) {
      return returnSrc(f, this.windowHeight)
    },
    // now storing data for this page in global state in router
    // getData: function() {
    //   const self = this
    //   api.getPage(self.$route.name).then((response) => {
    //     self.data = response.data
    //   }).catch(error => {
    //     console.log(error)
    //   })
    // }
  }
}
</script>

<style lang="scss">

@import "../styles/_helpers.scss";

$mobileLinkHeight: 65px;

.info-view {
  position: fixed;
  top: 0;
  margin-top: $nav-height;
  // this height is contingent on footer height being same as header height
  // --- may need to be updated
  height: calc(100% - (#{$nav-height} * 2));
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.info-wrap {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  height: 100%;
  @include bp-xs {
    position: relative;
    display: flex;
    flex-flow: column;
    // fallback 
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    // ...seems to be necessary
    // position: absolute;
  }
  &_left {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    @include bp-xs {
      // height: 66.6%;
      flex: unset;
      // height: 50%;
      display: inline-block;
      width: 100%;
      justify-content: unset;
      // watch out for vh here
      height: calc((100% - #{$nav-height}) - (#{$mobileLinkHeight} * 2));
      overflow-y: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @include bp-md {
      flex-flow: row;
    }
    @include bp-lg {
      flex-flow: row;
    }
    @include bp-xl {
      flex-flow: row;
    }
    @include bp-xxl {
      flex-flow: row;
    }
  }
  &_right {
    // background: black;
    background: var(--highlight);
    width: 50%;
    height: 100%;
    flex: 1;
    background-size: cover;
    background-position: center center;
    @include bp-xs {
      width: 100%;
      // height: 33.3%;
      height: 50%;
    }
  }
}

.info-desc {
  padding: $margin;
  // @include type-16_body;
  @include type-30;
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  @include bp-xs {
    @include type-30;
    padding: $margin $margin 0 $margin;
  }
  @include bp-sm {
    @include type-24;
  }
  @include bp-md {
    @include type-24;
    width: 50%;
    border-right: solid black 1px;
  }
  @include bp-lg {
    width: 50%;
    border-right: solid black 1px;
  }
  @include bp-xl {
    width: 50%;
    border-right: solid black 1px;
  }
  @include bp-xxl {
    width: 50%;
    border-right: solid black 1px;
  }
}

.info-links {
  width: 100%;
  @include bp-md {
    width: 50%;
  }
  @include bp-lg {
    width: 50%;
  }
  @include bp-xl {
    width: 50%;
  }
  @include bp-xxl {
    width: 50%;
  }
  & li {
    display: flex;
    border-top: solid black 1px;
    @include bp-xs {
      // border-top: unset;
      &:last-child {
        border-bottom: solid black 1px;
      }
    }
    @include bp-md {
      border-top: unset;
      border-bottom: solid black 1px;
    }
    @include bp-lg {
      border-top: unset;
      border-bottom: solid black 1px;
    }
    @include bp-xl {
      border-top: unset;
      border-bottom: solid black 1px;
    }
    @include bp-xxl {
      border-top: unset;
      border-bottom: solid black 1px;
    }
    & a {
      @include type-30;
      padding: 2.2rem $margin 2.2rem $margin;
      display: flex;
      width: 100%;
      @include bp-md {
        @include type-24;
      }
      @include bp-sm {
        @include type-24;
      }
      &:hover {
        @include italic;
        // background: black;
        background: var(--highlight);
        color: white;
      }
    }
  }
}

.info-image {
  width: 100%;
  padding: 0 $margin $margin $margin;
  & img {
    width: 100%;
  }
}

.info-footer {
  background: white;
  width: 100%;
  height: calc(#{$mobileLinkHeight} * 2);
  z-index: 100;
  &_links {
    width: 100%;
    display: flex;
    flex-flow: column;
    list-style: none;
    height: 100%;
    & li {
      width: 100%;
      height: 50%;
      border-top: solid black 1px;
      & a {
        display: flex;
        align-items: center;
        // justify-content: center;
        padding: 0 $margin 0 $margin;
        width: 100%;
        height: 100%;
        // padding: 2.2rem $margin 2.2rem $margin;
        @include type-30;
        &:hover {
          @include italic;
          // background: black;
          background: var(--highlight);
          color: white;
        }
      }
    }
  }
}


</style>
